<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-success card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i> Waren</h4>
                        <p>Verfügbare Produkte</p>
                    </div>

                    <div class="card-tools">
                        <div class="row">
                            <div class="col-md-12">
                                <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                    <ul class="navbar-nav">  
                                        <li class="nav-item">
                                            <!-- <button type="button" class="mr-1 btn btn-sm btn-success" @click="getResults" ><i class="fas fa-sync"></i></button>        -->
                                            <div class="mr-1 btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-fw fa-download"></i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">        
                                                    <a class="dropdown-item" href="#" @click="downloadIngredientsAsPdf">PDF</a>
                                                    <a class="dropdown-item" href="#" @click="downloadIngredientsAsCsv">Excel / CSV</a>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="mr-1 btn btn-default btn-sm" @click="loadIngredients">
                                                <i class="fas fa-fw fa-sync"></i>
                                            </button>
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('ingredients.create')">
                                                <i class="fas fa-fw fa-plus"></i>
                                            </button>
                                        </li>                                 
                                    </ul>                  
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <template v-if="ingredients.data.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th><input type="checkbox" v-model="selectAll" /></th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('name')">Name</a>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>HAN</th>
                                    <th>Type</th>
                                    <th>
                                        Preis
                                    </th>
                                    <th>VPE</th>
                                    <th>
                                        Einheit
                                    </th>
                                    <th style="width: 140px;">Aktionen</th>                 
                                </thead>
                                <tbody>
                                    <tr v-for="(ingredient,index) in ingredients.data" :key="ingredient.id">
                                        <td><input type="checkbox" :value="ingredient.id" v-model="selectedIngredients"/></td>
                                        <td>{{ ingredient.name }}</td>
                                        <td>{{ ingredient.han }}</td>
                                        <td>
                                            <template v-if="ingredient.type == 'food'">
                                                <span class="badge badge-primary">Lebensmittel</span>
                                            </template> 
                                            <template v-if="ingredient.type == 'package'">
                                                <span class="badge badge-secondary">Verpackung</span>
                                            </template>
                                        </td>
                                        <td>{{ ingredient.price | toCurrency}}</td>
                                        <td>{{ ingredient.vpe }}</td>
                                        <td>{{ ingredient.article_unit.description }}</td>
                                        <td>
                                            <b-button size="xs" @click="createOrderModal(ingredient)" class="mr-1" variant="secondary" v-if="$auth.check('ingredients.edit')"><i class="fas fa-fw fa-notes-medical"></i></b-button>
                                            <b-button size="xs" @click="showOrders(index)" class="mr-1" variant="success" v-if="$auth.check('ingredients.edit')"><i class="fas fa-fw fa-clipboard-list"></i></b-button>
                                            <b-button size="xs" @click="editModal(ingredient)" class="mr-1" variant="warning" v-if="$auth.check('ingredients.edit')"><i class="fas fa-fw fa-edit"></i></b-button>
                                            <b-button size="xs" @click="deleteIngredient(ingredient.id)" variant="danger" v-if="$auth.check('ingredients.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>

                    <template v-else>
                        <h5>Keine Produkte gefunden</h5>
                    </template>
                </div>

                <div class="card-footer">
                    <template v-if="ingredients.data.length > 0">
                        <div class="row align-items-center">
                            <div class="col-md-9">
                                <pagination class="float-left" :data="ingredients" @pagination-change-page="loadIngredients" :limit="3"></pagination>
                            </div>
                            <div class="col-md-2">
                                <span class="float-right">Anzeige Eintrag {{ ingredients.meta.from }} - {{ ingredients.meta.to }} von {{ ingredients.meta.total }}</span>
                            </div>
                            <div class="col-md-1">
                                <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                    <option value="25">25 Artikel</option>
                                    <option value="50">50 Artikel</option>
                                    <option value="75">75 Artikel</option>
                                    <option value="100">100 Artikel</option>
                                    <option value="125">125 Artikel</option>
                                    <option value="150">150 Artikel</option>
                                </select>
                            </div>
                        </div>                        
                    </template>
                </div>
            </div>            
        </div>
    </div>

    <b-modal id="ingredientModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, ingredientForm.id)">
        <div class="row">
            <div class="col-md-12">

                <div class="form-group">
                    <label for="name" class="control-label">Name*</label>
                    <input v-model="ingredientForm.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': ingredientForm.errors.has('name')}">
                    <has-error :form="ingredientForm" field="name"></has-error>
                </div>

                <div class="form-group">
                    <label for="han" class="control-label">HAN</label>
                    <input v-model="ingredientForm.han" type="text" class="form-control form-control-sm" :class="{'is-invalid': ingredientForm.errors.has('han')}">
                    <has-error :form="ingredientForm" field="han"></has-error>
                </div>

                <div class="form-group">
                    <label for="type" class="control-label">Typ*</label>
                    <select class="form-control form-control-sm" v-model="ingredientForm.type">
                        <option value="food">Lebensmittel</option>
                        <option value="package">Verpackung</option>
                    </select>
                    <has-error :form="ingredientForm" field="type"></has-error>
                </div>

                <div class="form-group">
                    <label for="vpe" class="control-label">VPE</label>
                    <input v-model="ingredientForm.vpe" type="text" class="form-control form-control-sm" :class="{'is-invalid': ingredientForm.errors.has('vpe')}">
                    <has-error :form="ingredientForm" field="vpe"></has-error>
                </div>

                <div class="form-group">
                    <label for="article_unit_id" class="control-label">Einheit</label>
                    <select class="form-control form-control-sm" v-model="ingredientForm.article_unit_id">
                        <option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.description }}</option>
                    </select>
                    <has-error :form="ingredientForm" field="article_unit_id"></has-error>
                </div>

                <!-- <div class="form-group">
                    <label for="price" class="control-label">Preis (Netto)</label>
                    <input v-model="ingredientForm.price" type="number" class="form-control form-control-sm" :class="{'is-invalid': ingredientForm.errors.has('price')}">
                    <has-error :form="ingredientForm" field="price"></has-error>
                </div> -->



                <div class="form-group">
                    <label for="url" class="control-label">URL</label>
                    <input v-model="ingredientForm.url" type="text" class="form-control form-control-sm" :class="{'is-invalid': ingredientForm.errors.has('url')}">
                    <has-error :form="ingredientForm" field="url"></has-error>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal id="orderModal" title="Bestellung hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOrderOk">
        <div class="row">
            <div class="col-md-12">

                <div class="form-group">
                    <label for="date" class="control-label">Datum*</label>
                    <input v-model="orderForm.date" type="date" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('date')}">
                    <has-error :form="orderForm" field="date"></has-error>
                </div>

                <div class="form-group">
                    <label for="quantity" class="control-label">Menge*</label>
                    <input v-model="orderForm.quantity" type="number" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('quantity')}">
                    <has-error :form="orderForm" field="quantity"></has-error>
                </div>

                <div class="form-group">
                    <label for="price" class="control-label">Preis (EK/Netto)*</label>
                    <input v-model="orderForm.price" type="number" class="form-control form-control-sm" :class="{'is-invalid': orderForm.errors.has('price')}">
                    <has-error :form="orderForm" field="price"></has-error>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal id="orderShowModal" title="Bestellungen ansehen" ok-only ok-variant="secondary" ok-title="OK" size="xl" @ok="handleOrderShowOk" @hide="handleOrderShowOk">
        <div class="row">
            <div class="col-md-12">
                <template v-if="orders.length > 0">
                <div class="table-responsive">
                    <table class="table table-hover table-sm">
                        <thead>
                            <th>
                                Datum
                            </th>
                            <th>
                                Preis
                            </th>
                            <th>
                                {{ unit }}
                            </th>
                            <th>
                                Preis / {{ unit }}
                            </th>
                            <th style="width: 140px;">Aktionen</th>                 
                        </thead>
                        <tbody>
                            <tr v-for="(order) in orders" :key="order.id">
                                <td>{{ order.date | formatDate }}</td>
                                <td>{{ order.price | toCurrency}}</td>
                                <td>{{ order.quantity }}</td>
                                <td>{{ order.price / order.quantity | toCurrency }}</td>
                                <td>
                                    <b-button size="xs" @click="deleteIngredientOrder(order)" variant="danger" v-if="$auth.check('ingredients.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </template>
                <template v-else>
                    <h3>Keine Bestellungen</h3>
                </template>
            </div>    
        </div>
    </b-modal>

</div>
</template>

<script>
export default {
    name: "Ingredients",

    props:[
        'wholesaler'
    ],

    data(){
        return{
            ingredients: {
                data: [],
            },
            units: [],
            selectedIngredients: [],
            params: {
                sort_field: 'name',
                sort_direction: 'asc',
                per_page: 25,
                wholesaler: this.$route.params.id,
            },
            search: '',
            page: 1,
            ingredientForm: new window.Form({
                id: "",
                wholesaler_id: "",
                name: '',
                // price: '',
                url: '',
                han: '',
                vpe: '',
                type: 'food',
                article_unit_id: '',
            }),
            modalTitle: "Neue Ware erfassen",
            editMode: false,
            orderForm: new window.Form({
                id: "",
                ingredient_id: "",
                date: "",
                quantity: "",
                price:"",
            }),
            orders: [],
            unit: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.loadIngredients();
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.ingredients.data ? this.selectedIngredients.length == this.ingredients.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.ingredients.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedIngredients = selected;
            }
        }
    },

    methods:{
        downloadIngredientsAsPdf(){
            this.axios
                .get("/wholesalers/" + this.$route.params.id + '/export/ingredients/pdf',{
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", this.wholesaler.name + '_Waren.pdf');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        downloadIngredientsAsCsv(){
            this.axios
                .get("/wholesalers/" + this.$route.params.id + '/export/ingredients/csv',{
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", this.wholesaler.name + '_Artikel.xlsx');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteIngredientOrder(order){
            this.$swal({
                title: "Möchtest du die Ware wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                denyButtonColor: "#3085d6",
                confirmButtonText: `Löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.axios
                        .delete("/ingredients/" + order.ingredient_id + '/orders/' + order.id)
                        .then(() => {
                            this.loadIngredients();
                            this.$bvModal.hide("orderShowModal");
                            this.$swal({
                                icon: "success",
                                title: "Bestellung gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                            
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufennnnn",
                            });
                        });
                }
            });
        },

        handleOrderShowOk() {
            this.$bvModal.hide("orderShowModal");
        },

        showOrders(index){
            this.orders = [];
            this.unit = '';
            this.orders = this.ingredients.data[index].orders;
            this.unit = this.ingredients.data[index].article_unit.description;
            this.$bvModal.show("orderShowModal");
        },

        handleOrderOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleOrderSubmit()
        },

        handleOrderSubmit() {
            this.addOrder();     
        },

        createOrderModal(data) {
            this.orderForm.clear();
            this.orderForm.reset();
            this.orderForm.ingredient_id = data.id;
            this.$bvModal.show("orderModal");
        },

        addOrder(){
            this.orderForm
                .post("/ingredients/" + this.orderForm.ingredient_id + '/orders')
                .then(() => {
                    this.loadIngredients();
                    this.$bvModal.hide("orderModal");
                    this.$swal({
                        icon: "success",
                        title: "Bestellung gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });

        },
        deleteIngredient(id){
            this.$swal({
                title: "Möchtest du die Ware wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                denyButtonColor: "#3085d6",
                confirmButtonText: `Löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.ingredientForm
                        .delete("/ingredients/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Ware wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadIngredients();
                        })
                        .catch((res) => {
                            var message = "Da ist etwas schief gelaufen";

                            if(res.response.status == 500){
                                message = res.response.data.error
                            }


                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: message,
                            });
                        });
                }
            });
        },

        createIngredient(){
            this.ingredientForm
                .post("/ingredients")
                .then(() => {
                    this.loadIngredients();
                    this.$bvModal.hide("ingredientModal");
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        editIngredient(id){
            this.ingredientForm
                .put("/ingredients/" + id)
                .then(() => {
                    this.loadIngredients();
                    this.$bvModal.hide("ingredientModal");
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });       
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        handleOk(bvModalEvt, id){
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editIngredient(id);
            }
            else
            {
                //Create User
                this.createIngredient();
            }
        },

        createModal(){
            this.ingredientForm.clear();
            this.ingredientForm.reset();
            this.editMode = false;
            this.ingredientForm.wholesaler_id = this.wholesaler.id;
            this.$bvModal.show("ingredientModal");
        },

        editModal(Ingredient){
            this.editMode = true;
            this.modalTitle = "Ware bearbeiten";
            this.ingredientForm.reset();
            this.ingredientForm.fill(Ingredient);
            this.ingredientForm.wholesaler_id = this.wholesaler.id;
            this.$bvModal.show("ingredientModal");
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        loadIngredients(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/ingredients",{
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.ingredients = response.data;
                    this.page = page;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadUnits() {
            this.axios
                .get("/units")
                .then((response) => {
                    this.units = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadIngredients();
        this.loadUnits();
    },
}
</script>

<style>

</style>